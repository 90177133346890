<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <h3>گزارش خدمات شیفت</h3>
      </v-card-title>
      <v-tabs
        class="mt-1"
        background-color="transparent"
        vertical
        id="tabs"
        v-model="tab"
        :style="
          deviceType == 'mobile'
            ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
            : ''
        "
      >
        <v-tab
          class="tab-title first-tab-item-br"
          v-if="
            shiftInfo.visits.length ||
            shiftInfo.balinis.length ||
            shiftInfo.outBalinis.length ||
            shiftInfo.surgery.length ||
            shiftInfo.surgeryOut.length
          "
        >
          درمانگاه عمومی
        </v-tab>
        <v-tab
          v-if="shiftInfo.takhasos.length || shiftInfo.takhasosClinical.length"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          درمانگاه تخصصی
        </v-tab>

        <v-tab
          v-if="shiftInfo.dentistryFinance.length"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          دندانپزشکی
        </v-tab>
        <v-tab
          v-if="shiftInfo.allLabs.length || shiftInfo.labFinance.length"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          آزمایشگاه
        </v-tab>
        <v-tab
          v-if="shiftInfo.commodity.length"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          اقلام مصرفی
        </v-tab>
        <v-tab
          v-if="shiftInfo.charityFinanceList.length"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          دیوار مهربانی سلامت
        </v-tab>
        <!--<v-tab
          v-if="shiftInfo.medicine.length"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          دارو
        </v-tab>-->
        <v-tab
          class="tab-title"
          :class="role == 'reception' ? 'last-tab-item-br' : ''"
          :style="role == 'reception' ? '' : 'border-radius: 0 !important'"
        >
          کلی
        </v-tab>
        <v-tab v-if="role == 'admin'" class="tab-title last-tab-item-br">
          گزارش پایان شیفت
        </v-tab>

        <v-tabs-items v-model="tab" id="shiftReport" ref="docReport">
          <!-- درمانگاه عمومی -->
          <v-tab-item
            v-if="
              shiftInfo.visits.length ||
              shiftInfo.balinis.length ||
              shiftInfo.outBalinis.length ||
              shiftInfo.surgery.length ||
              shiftInfo.surgeryOut.length
            "
          >
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span style="line-height: 30px">
                      {{ shiftInfo.doctorName.join(" - ") }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >
                    <span style="line-height: 30px">
                      {{ shiftInfo.nurseName.join(" - ") }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>

                    <span style="line-height: 30px">
                      {{ shiftInfo.receptionName.join(" - ") }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div class="break-avoid mt-2">
                  <v-row>
                    <v-col>
                      <h5>
                        <strong>مجموع درمانگاه عمومی</strong>
                      </h5>
                    </v-col>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn float-end dontPrint"
                          @click="printShiftReport"
                          v-show="!printing"
                          >پرینت گزارش درمانگاه عمومی</v-btn
                        >
                      </div></v-col
                    >
                  </v-row>

                  <hr />
                  <b-table
                    responsive
                    show-empty
                    :fields="generalTotalField"
                    :items="shiftInfo.generalTotal"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.visits.length">
                  <hr />
                  <h5>
                    <strong>ویزیت</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.visitTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(shiftInfo.visitTotalTotal).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(shiftInfo.visitTotalClinic).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.visitTotalCosts).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>

                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.visits"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid mt-5" v-if="shiftInfo.balinis.length">
                  <hr />
                  <h5>
                    <strong>خدمات پرستاری</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.baliniTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(shiftInfo.baliniTotalTotal).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(shiftInfo.baliniTotalClinic).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.baliniTotalCosts).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.balinis"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div
                  class="break-avoid mt-5"
                  v-if="shiftInfo.outBalinis.length"
                >
                  <hr />
                  <h5>
                    <strong>خدمات پرستاری (خارج از درمانگاه)</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>

                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.outBalinis"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.surgery.length">
                  <hr />
                  <h5>
                    <strong>جراحی سرپایی </strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.surgeryTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(shiftInfo.surgeryTotalTotal).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.surgeryTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.surgeryTotalCosts).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.surgery"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.surgeryOut.length">
                  <hr />
                  <h5>
                    <strong>جراحی سرپایی (خارج از درمانگاه)</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :

                          {{
                            Number(
                              shiftInfo.surgeryOutTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.surgeryOut"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- درمانگاه تخصصی -->
          <v-tab-item
            v-if="
              shiftInfo.takhasos.length || shiftInfo.takhasosClinical.length
            "
          >
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div
                  class="break-avoid"
                  v-if="
                    shiftInfo.takhasosClinical.length ||
                    shiftInfo.takhasos.length
                  "
                >
                  <v-row class="mt-2">
                    <v-col>
                      <h5>
                        <strong>مجموع درمانگاه تخصصی</strong>
                      </h5>
                    </v-col>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn float-end dontPrint"
                          @click="printShiftReport"
                          v-show="!printing"
                          >پرینت گزارش درمانگاه تخصصی</v-btn
                        >
                      </div></v-col
                    >
                  </v-row>
                  <hr />
                  <b-table
                    responsive
                    show-empty
                    :fields="takhasTotalField"
                    :items="shiftInfo.takhasTotal"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.takhasos.length">
                  <hr />
                  <h5>
                    <strong>ویزیت تخصصی</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.takhasTotalCountVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :

                          {{
                            Number(
                              shiftInfo.takhasTotalTotalVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalClinicVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalCostsVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.takhasos"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div
                  class="break-avoid"
                  v-if="shiftInfo.takhasosClinical.length"
                >
                  <hr />
                  <h5>
                    <strong>خدمات تخصصی</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.takhasTotalCountClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :

                          {{
                            Number(
                              shiftInfo.takhasTotalTotalClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalClinicClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalCostsClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.takhasosClinical"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- دندانپزشکی -->
          <v-tab-item v-if="shiftInfo.dentistryFinance.length">
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div
                  class="break-avoid"
                  v-if="shiftInfo.dentistryFinance.length"
                >
                  <v-row>
                    <v-col>
                      <h5>
                        <strong>دندانپزشکی</strong>
                      </h5>
                    </v-col>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn float-end dontPrint"
                          @click="printShiftReport"
                          v-show="!printing"
                          >پرینت گزارش دندانپزشکی</v-btn
                        >
                      </div></v-col
                    >
                  </v-row>

                  <!--<v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.dentistryTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.dentistryTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong
                          ><v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.dentistryTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>-->
                  <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                  <!--<v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.dentistryTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.dentistry"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>-->
                  <hr />
                  <h6 class="mt-2 font-weight-bold">مالی</h6>
                  <h6
                    class="common-text common-div my-2"
                    v-if="shiftInfo.totalDentistryFinance"
                  >
                    <strong>
                      <v-icon color="primary">mdi-cash-multiple</v-icon>
                      مجموع مالی:
                      {{
                        Number(shiftInfo.totalDentistryFinance).toLocaleString()
                      }}
                      ریال
                    </strong>
                  </h6>
                  <b-table
                    responsive
                    show-empty
                    :fields="dentistryFinancialFields"
                    :items="shiftInfo.dentistryFinance"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- آزمایشگاه -->
          <v-tab-item
            v-if="shiftInfo.allLabs.length || shiftInfo.labFinance.length"
          >
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div class="break-avoid" v-if="shiftInfo.laboratory.length">
                  <v-row>
                    <v-col>
                      <h5>
                        <strong>آزمایشگاه</strong>
                      </h5>
                    </v-col>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn float-end dontPrint"
                          @click="printShiftReport"
                          v-show="!printing"
                          >پرینت گزارش آزمایشگاه</v-btn
                        >
                      </div></v-col
                    >
                  </v-row>
                  <hr />
                  <h6 class="mt-2 font-weight-bold">درخواست‌ها</h6>
                  <b-table
                    responsive
                    show-empty
                    :fields="labRequestFields"
                    :items="shiftInfo.allLabs"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                  <hr />
                  <h6 class="mt-2 font-weight-bold">مجموع درخواست‌ها</h6>

                  <v-row style="margin: 20px 10px">
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.laboratory[0].count
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.laboratory[0].totalCost
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.laboratory[0].totalCost
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="labFields"
                    :items="shiftInfo.laboratory"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                  <hr />
                  <h6 class="mt-2 font-weight-bold">مالی</h6>
                  <h6
                    class="common-text common-div my-2"
                    v-if="shiftInfo.totalLabFinance"
                  >
                    <strong>
                      <v-icon color="primary">mdi-cash-multiple</v-icon>
                      مجموع مالی:
                      {{ Number(shiftInfo.totalLabFinance).toLocaleString() }}
                      ریال
                    </strong>
                  </h6>
                  <b-table
                    responsive
                    show-empty
                    :fields="labFinancialFields"
                    :items="shiftInfo.labFinance"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- اقلام مصرفی -->
          <v-tab-item v-if="shiftInfo.commodity.length">
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div class="break-avoid" v-if="shiftInfo.commodity.length">
                  <v-row>
                    <v-col>
                      <h5>
                        <strong>اقلام مصرفی</strong>
                      </h5>
                    </v-col>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn float-end dontPrint"
                          @click="printShiftReport"
                          v-show="!printing"
                          >پرینت گزارش اقلام مصرفی</v-btn
                        >
                      </div></v-col
                    >
                  </v-row>
                  <hr />
                  <div class="break-avoid" v-if="shiftInfo.commodity.length">
                    <v-row style="margin: 20px 10px">
                      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                        <div class="common-text common-div">
                          <strong>
                            <v-icon color="primary">mdi-counter</v-icon>
                            تعداد :
                            {{
                              Number(
                                shiftInfo.commodityTotalCount
                              ).toLocaleString()
                            }}
                          </strong>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                        <div class="common-text common-div">
                          <strong>
                            <v-icon color="primary"
                              >mdi-hospital-building</v-icon
                            >
                            کارکرد درمانگاه :
                            {{
                              Number(
                                shiftInfo.commodityTotalCosts
                              ).toLocaleString()
                            }}
                          </strong>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                        <div class="common-text common-div">
                          <strong>
                            <v-icon color="primary">mdi-cash-plus</v-icon>
                            دریافتی :
                            {{
                              Number(
                                shiftInfo.commodityTotalCosts
                              ).toLocaleString()
                            }}
                          </strong>
                        </div>
                      </v-col>
                    </v-row>
                    <b-table
                      responsive
                      show-empty
                      :fields="commodityField"
                      :items="shiftInfo.commodity"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            typeof data.value === "number"
                              ? Number(data.value).toLocaleString()
                              : data.value
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- دیوار مهربانی سلامت -->
          <v-tab-item v-if="shiftInfo.charityFinanceList.length">
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div
                  class="break-avoid"
                  v-if="shiftInfo.charityFinanceList.length"
                >
                  <h6 class="mt-2 font-weight-bold">مالی</h6>
                  <h6
                    class="common-text common-div my-2"
                    v-if="shiftInfo.totalCharity"
                  >
                    <strong>
                      <v-icon color="primary">mdi-cash-multiple</v-icon>
                      مجموع مالی:
                      {{ Number(shiftInfo.totalCharity).toLocaleString() }}
                      ریال
                    </strong>
                  </h6>
                  <b-table
                    responsive
                    show-empty
                    :fields="charityFields"
                    :items="shiftInfo.charityFinanceList"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString() + " ریال"
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- دارو -->
          <!--<v-tab-item
            v-if="shiftInfo.medicine.length"
          >
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr />
              <v-card class="pa-3">
                <div class="break-avoid" v-if="shiftInfo.medicine.length">
                  <v-row>
                    <v-col>
                      <h5>
                        <strong>دارو</strong>
                      </h5>
                    </v-col>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn float-end dontPrint"
                          @click="printShiftReport"
                          v-show="!printing"
                          >پرینت گزارش دارو</v-btn
                        >
                      </div></v-col
                    >
                  </v-row>
                  <hr />
                  <div class="break-avoid" v-if="shiftInfo.medicine.length">


                    <v-row style="margin: 20px 10px">
                      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                        <div class="common-text common-div">
                          <strong>
                            <v-icon color="primary">mdi-counter</v-icon>
                            تعداد :
                            {{
                              Number(
                                shiftInfo.medicineTotalCount
                              ).toLocaleString()
                            }}
                          </strong>
                        </div>
                      </v-col>
                         <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.medicineTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                        <div class="common-text common-div">
                          <strong>
                            <v-icon color="primary">mdi-cash-plus</v-icon>
                            دریافتی :
                            {{
                              Number(
                                shiftInfo.medicineTotalCost
                              ).toLocaleString()
                            }}
                          </strong>
                        </div>
                      </v-col>
                    </v-row>
                    <b-table
                      responsive
                      show-empty
                      :fields="commodityField"
                      :items="shiftInfo.medicine"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            typeof data.value === "number"
                              ? Number(data.value).toLocaleString()
                              : data.value
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>-->
          <!--کلی-->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 500px">
              <v-row class="px-2 pt-2">
                <v-col>
                  <h5 class="common-text">
                    <span class="primary--text font-weight-bold"
                      >عنوان شیفت:</span
                    >
                    {{ shiftInfo.subject }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    از: <span dir="ltr">{{ shiftInfo.startDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.startDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.startTime
                    }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pt-0">
                  <h5 class="common-text">
                    تا: <span dir="ltr">{{ shiftInfo.endDate }}</span>
                    {{
                      weekDict[
                        moment(shiftInfo.endDate, "jYYYY/jMM/jDD").weekday()
                      ]
                    }}
                    <span dir="ltr" class="common-text">{{
                      shiftInfo.endTime
                    }}</span>
                  </h5>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col
                  v-if="shiftInfo.doctorName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.doctorName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پزشک:</span>
                    دکتر {{ shiftInfo.doctorName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">پزشکان:</span>
                    <span
                      v-for="doc in shiftInfo.doctorName"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.nurseName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.nurseName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پرستار:</span>
                    {{ shiftInfo.nurseName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold"
                      >پرستاران:</span
                    >

                    <span
                      v-for="nurse in shiftInfo.nurseName"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </h5>
                </v-col>
                <v-col
                  v-if="shiftInfo.receptionName.length > 0"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="pt-0"
                >
                  <h5
                    v-if="shiftInfo.receptionName.length == 1"
                    class="common-text"
                  >
                    <span class="primary--text font-weight-bold">پذیرش:</span>
                    {{ shiftInfo.receptionName[0] }}
                  </h5>
                  <h5 v-else class="common-text">
                    <span class="primary--text font-weight-bold">
                      کارمندان پذیرش:
                    </span>
                    <span
                      v-for="reception in shiftInfo.receptionName"
                      :key="reception.index"
                      style="line-height: 30px"
                    >
                      - {{ reception }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <hr v-show="!printing" />
              <v-card class="pa-3">
                <v-card-title>
                  <v-row>
                    <v-col
                      ><div>
                        <v-btn
                          class="secondary-btn dontPrint"
                          @click="printShiftReport"
                          :disabled="computedDisabled"
                          v-show="!printing"
                          >پرینت گزارش خدمات شیفت</v-btn
                        >
                      </div></v-col
                    >
                    <v-col>
                      <v-btn
                        @click="$refs.shiftReportExcel.$el.click()"
                        style="float: left"
                        v-show="!printing"
                        class="secondary-btn dontPrint"
                        :disabled="computedDisabled"
                        v-if="role != 'reception'"
                        >دریافت اکسل خدمات شیفت</v-btn
                      >
                      <vue-excel-xlsx
                        v-show="false"
                        ref="shiftReportExcel"
                        :data="excelDocItems"
                        :columns="
                          excelDocFields.map((x) => {
                            return {
                              label: x.label,
                              field: x.key,
                            };
                          })
                        "
                        :filename="'گزارش شیفت'"
                        :sheetname="currentDate"
                      >
                        دریافت اکسل
                      </vue-excel-xlsx>
                    </v-col>
                  </v-row>
                </v-card-title>
                <hr class="mb-5" v-if="role == 'admin'" v-show="!printing" />
                <div class="break-avoid" v-if="role == 'admin'">
                  <div v-if="shiftInfo.personel.length">
                    <h5>
                      <strong>ساعات ورود و خروج</strong>
                    </h5>
                    <b-table
                      responsive
                      show-empty
                      :fields="inOutFields"
                      :items="shiftInfo.personel"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <hr class="mb-4" />

                <v-row v-if="!printing" class="break-avoid my-2 px-4">
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <div
                      v-if="shiftInfo.totalCost != ''"
                      class="sum-div px-10 py-5"
                    >
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                      >
                        <div style="cursor: pointer" @click="infoDialog = true">
                          <v-icon class="pe-2" color="dark"
                            >mdi-information-outline</v-icon
                          >
                        </div>

                        <span
                          class="font-weight-bold primary--text"
                          :class="printing ? 'h6' : 'h2'"
                        >
                          کل کارکرد:
                        </span>
                        <span
                          class="primary--text font-weight-bold"
                          :class="printing ? 'h6' : 'h2'"
                        >
                          {{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>
                      <v-row class="mt-3">
                        <v-col>
                          <hr v-if="printing" />
                          <div class="d-flex flex-column justify-space-between">
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارکرد درمانگاه عمومی
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.generalTotalCost
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارکرد درمانگاه تخصصی
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.expertTotalCost
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارکرد خدمات در منزل
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.homeCareTotalCost
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارکرد آزمایشگاه
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.laboratory[0].totalCost
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارکرد مشاوره
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.otherTotalCost
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارکرد اقلام مصرفی
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.commodityTotalCosts
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>

                            <div v-if="role != 'reception'" class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                جمع هزینه اقلام حفاظتی
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  Number(
                                    shiftInfo.totalClinicShare
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </div>
                          </div></v-col
                        >
                      </v-row>
                      <!--<div
                        class="mt-4 text-center mb-3"
                        v-if="role != 'reception'"
                      >
                        <span
                          :class="printing ? 'h6' : 'h3'"
                          class="font-weight-bold primary--text"
                        >
                          کارکرد بدون اقلام حفاظتی:
                        </span>
                        <span
                          class="font-weight-bold primary--text"
                          :class="printing ? 'h6' : 'h3'"
                        >
                          {{ Number(shiftInfo.totalCost).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->

                      <!-- <div class="mt-3">
                        <span
                          class="font-weight-bold"
                          :class="printing ? 'h6' : 'h3'"
                        >
                          جمع دریافتی:
                        </span>
                        <span
                          class="float-end h2"
                          style="width: 35%"
                          :class="printing ? 'h6' : 'h3'"
                          >{{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                    <div class="sum-div px-10 py-5">
                      <v-row>
                        <v-col class="pb-0">
                          <div
                            :class="!printing ? 'justify-content-center' : ''"
                            class="mt-1 d-flex"
                          >
                            <div
                              style="cursor: pointer"
                              @click="infoDialog = true"
                            >
                              <v-icon class="pe-2" color="dark"
                                >mdi-information-outline</v-icon
                              >
                            </div>
                            <span
                              class="font-weight-bold font-wight-bold primary--text"
                              :class="printing ? 'h6' : 'h2'"
                            >
                              مجموع درآمد:
                            </span>

                            <span
                              class="font-weight-bold primary--text"
                              :class="printing ? 'h6' : 'h2'"
                              >{{
                                Number(
                                  shiftInfo.totalFinanceIncome
                                ).toLocaleString()
                              }}
                              ریال
                            </span>
                          </div></v-col
                        >
                        <v-col class="pb-0">
                          <div
                            class="mt-1"
                            :class="!printing ? 'text-center' : ''"
                            v-if="role == 'admin'"
                          >
                            <span
                              class="font-weight-bold font-wight-bold primary--text"
                              :class="printing ? 'h6' : 'h2'"
                            >
                              سهم پزشک شیفت:
                            </span>

                            <span
                              class="font-weight-bold primary--text"
                              :class="printing ? 'h6' : 'h2'"
                              >{{
                                Number(shiftInfo.doctorShare).toLocaleString()
                              }}
                              ریال
                            </span>
                          </div></v-col
                        >
                      </v-row>
                      <hr v-if="printing" />
                      <v-row class="mt-0">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          class="pe-5 pt-0"
                        >
                          <div class="d-flex flex-column justify-space-between">
                            <div
                              v-for="method in shiftInfo.perPaymentMethods"
                              :key="method.value"
                              class="mt-3"
                            >
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{ method.text }}
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  method.balance
                                    ? Number(method.balance).toLocaleString() +
                                      " ریال"
                                    : "-"
                                }}
                              </span>
                            </div>

                            <!-- <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارتخوان
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.pos != "-"
                                    ? Number(shiftInfo.pos).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                نقدی
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.cash != "-"
                                    ? Number(shiftInfo.cash).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                چک
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.cheque != "-"
                                    ? Number(shiftInfo.cheque).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                کارت به کارت
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.card != "-"
                                    ? Number(shiftInfo.card).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>
                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                دیوار مهربانی سلامت
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.totalCharity != "-"
                                    ? Number(
                                        shiftInfo.totalCharity
                                      ).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div> -->
                          </div>
                        </v-col>

                        <v-divider
                          vertical
                          class="d-none d-lg-block"
                          style="min-height: 60%; max-height: 75%"
                        ></v-divider>

                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          class="ps-5 pt-0"
                        >
                          <v-divider class="d-block d-lg-none pb-4"></v-divider>
                          <div class="d-flex flex-column justify-space-between">
                            <div class="mt-3">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                درآمد ناشی از درمانگاه
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.generalIncome != "-"
                                    ? Number(
                                        shiftInfo.generalIncome
                                      ).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                درآمد ناشی از خدمات در منزل
                              </span>
                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.outRequestIncome != "-"
                                    ? Number(
                                        shiftInfo.outRequestIncome
                                      ).toLocaleString()
                                    : "-"
                                }}
                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                درآمد ناشی از آزمایشگاه
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.labIncome != "-"
                                    ? Number(
                                        shiftInfo.labIncome
                                      ).toLocaleString()
                                    : "-"
                                }}

                                ریال
                              </span>
                            </div>

                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                درآمد ناشی از دندانپزشکی
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.dentisteryIncome != "-"
                                    ? Number(
                                        shiftInfo.dentisteryIncome
                                      ).toLocaleString()
                                    : "-"
                                }}

                                ریال
                              </span>
                            </div>
                            <div class="mt-4">
                              <span
                                class="font-weight-bold"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                درآمد ناشی از مشاوره
                              </span>

                              <span
                                class="float-end"
                                :class="printing ? 'h6' : 'h4'"
                              >
                                {{
                                  shiftInfo.counselingIncome != "-"
                                    ? Number(
                                        shiftInfo.counselingIncome
                                      ).toLocaleString()
                                    : "-"
                                }}

                                ریال
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-row v-else-if="printing">
                  <v-col cols="4">
                    <div
                      v-if="shiftInfo.totalCost != ''"
                      class="sum-div px-10 py-5"
                    >
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                      >
                        <span class="font-weight-bold primary--text h5">
                          کل کارکرد:
                        </span>

                        <span class="primary--text font-weight-bold h5">
                          {{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>
                      <hr style="border-top: 2px solid grey" />
                      <div class="d-flex flex-column justify-space-between">
                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد درمانگاه عمومی
                          </span>
                          <span class="text-left h6" style="float: left">
                            {{
                              Number(
                                shiftInfo.generalTotalCost
                              ).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>
                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد درمانگاه تخصصی
                          </span>
                          <span class="h6" style="float: left">
                            {{
                              Number(shiftInfo.expertTotalCost).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>
                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد خدمات در منزل
                          </span>
                          <span class="float-end h6" style="float: left">
                            {{
                              Number(
                                shiftInfo.homeCareTotalCost
                              ).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد آزمایشگاه
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              Number(
                                shiftInfo.laboratory[0].totalCost
                              ).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد مشاوره
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              Number(shiftInfo.otherTotalCost).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>
                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            کارکرد اقلام مصرفی
                          </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              Number(
                                shiftInfo.commodityTotalCosts
                              ).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>

                        <div v-if="role != 'reception'" class="mt-3">
                          <span class="font-weight-bold h6">
                            جمع هزینه اقلام حفاظتی
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              Number(
                                shiftInfo.totalClinicShare
                              ).toLocaleString()
                            }}
                            ریال
                          </span>
                        </div>
                      </div>
                      <!--<div
                        class="mt-4 text-center mb-3"
                        v-if="role != 'reception'"
                      >
                        <span
                          :class="printing ? 'h6' : 'h3'"
                          class="font-weight-bold primary--text"
                        >
                          کارکرد بدون اقلام حفاظتی:
                        </span>
                        <span
                          class="font-weight-bold primary--text"
                          :class="printing ? 'h6' : 'h3'"
                        >
                          {{ Number(shiftInfo.totalCost).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->

                      <!-- <div class="mt-3">
                        <span
                          class="font-weight-bold"
                          :class="printing ? 'h6' : 'h3'"
                        >
                          جمع دریافتی:
                        </span>
                        <span
                          class="float-end h2"
                          style="width: 35%"
                          :class="printing ? 'h6' : 'h3'"
                          >{{ Number(shiftInfo.totalIncome).toLocaleString() }}
                          ریال
                        </span>
                      </div>-->
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="sum-div px-10 py-5">
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                      >
                        <span class="font-weight-bold primary--text h5">
                          مجموع درآمد:
                        </span>

                        <span class="primary--text font-weight-bold h5">
                          {{
                            Number(
                              shiftInfo.totalFinanceIncome
                            ).toLocaleString()
                          }}
                          ریال
                        </span>
                      </div>
                      <hr style="border-top: 2px solid grey" />
                      <div class="d-flex flex-column justify-space-between">
                        <div
                          class="mt-3"
                          v-for="method in shiftInfo.perPaymentMethods"
                          :key="method.value"
                        >
                          <span class="font-weight-bold h6">
                            {{ method.text }}</span
                          >
                          <span class="float-end h6" style="float: left" h6>
                            {{
                              method.balance
                                ? Number(method.balance).toLocaleString() +
                                  " ریال"
                                : "-"
                            }}
                          </span>
                        </div>

                        <!-- <div class="mt-3">
                          <span class="font-weight-bold h6"> کارتخواان </span>
                          <span class="float-end h6" style="float: left" h6>
                            {{
                              shiftInfo.pos != "-"
                                ? Number(shiftInfo.pos).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6"> نقدی </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.cash != "-"
                                ? Number(shiftInfo.cash).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6"> چک </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.cheque != "-"
                                ? Number(shiftInfo.cheque).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            کارت به کارت
                          </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.card != "-"
                                ? Number(shiftInfo.card).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>
                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            دیوار مهربانی سلامت
                          </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.totalCharity != "-"
                                ? Number(
                                    shiftInfo.totalCharity
                                  ).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div> -->
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="sum-div px-10 py-5">
                      <div
                        class="text-center mt-2 d-flex justify-content-center"
                        v-if="role == 'admin'"
                      >
                        <span class="font-weight-bold primary--text h5">
                          سهم پزشک شیفت:
                        </span>

                        <span class="primary--text font-weight-bold h5">
                          {{ Number(shiftInfo.doctorShare).toLocaleString() }}
                          ریال
                        </span>
                      </div>
                      <hr
                        style="border-top: 2px solid grey"
                        v-if="role == 'admin'"
                      />
                      <div
                        class="d-flex flex-column justify-space-between"
                        :style="role == 'admin' ? '' : 'margin-top:71px'"
                      >
                        <div class="mt-3">
                          <span class="font-weight-bold h6">
                            درآمد ناشی از درمانگاه
                          </span>
                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.generalIncome != "-"
                                ? Number(
                                    shiftInfo.generalIncome
                                  ).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            درآمد ناشی از خدمات در منزل
                          </span>
                          <span class="h6" style="float: left">
                            {{
                              shiftInfo.outRequestIncome != "-"
                                ? Number(
                                    shiftInfo.outRequestIncome
                                  ).toLocaleString()
                                : "-"
                            }}
                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            درآمد ناشی از آزمایشگاه
                          </span>

                          <span class="float-end h6" style="float: left">
                            {{
                              shiftInfo.labIncome != "-"
                                ? Number(shiftInfo.labIncome).toLocaleString()
                                : "-"
                            }}

                            ریال
                          </span>
                        </div>

                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            درآمد ناشی از دندانپزشکی
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              shiftInfo.dentisteryIncome != "-"
                                ? Number(
                                    shiftInfo.dentisteryIncome
                                  ).toLocaleString()
                                : "-"
                            }}

                            ریال
                          </span>
                        </div>
                        <div class="mt-4">
                          <span class="font-weight-bold h6">
                            درآمد ناشی از مشاوره
                          </span>

                          <span class="h6" style="float: left">
                            {{
                              shiftInfo.counselingIncome != "-"
                                ? Number(
                                    shiftInfo.counselingIncome
                                  ).toLocaleString()
                                : "-"
                            }}

                            ریال
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-dialog v-model="infoDialog" width="600">
                  <v-card class="px-3 py-5" style="border-radius: 20px">
                    <v-container>
                      <v-row class="px-2">
                        <v-col class="text-right">
                          <h4 class="cyan--text text--darken-4">راهنما</h4>
                        </v-col>
                        <v-col class="text-left">
                          <span
                            @click="infoDialog = false"
                            style="cursor: pointer"
                          >
                            <v-icon color="red">mdi-close</v-icon>
                          </span>
                        </v-col>
                      </v-row>
                      <v-card-text
                        class="text-justify blue-grey--text darken-4"
                      >
                        <h5>همکار گرامی،</h5>
                        <ul class="ps-5 mt-2">
                          <li class="h3" style="line-height: 30px">
                            داده‌های مربوط به کارکرد از عملکرد و مراجعات واقعی
                            بیماران به کلینیک استخراج شده است.
                          </li>
                          <li class="h3" style="line-heighst: 30px">
                            داده‌های مربوط به درآمد، از وجوه پرداختی استخراج شده
                            است.
                          </li>
                          <li class="h3" style="line-height: 30px">
                            در خصوص خدماتی که پرداخت آنها جزئی است، این داده‌ها
                            با یکدیگر تفاوت خواهند داشت.
                          </li>
                          <li class="h3" style="line-height: 30px">
                            در صورتی که کلیه خدمات ارائه شده در این شیفت، در
                            همین شیفت پرداخت شده باشند، این داده‌ها باید با
                            یکدیگر همخوانی و سازگاری داشته باشند.
                          </li>
                        </ul>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-dialog>

                <div class="break-avoid mt-4" v-if="shiftInfo.visits.length">
                  <hr />
                  <h5>
                    <strong>ویزیت</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.visitTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(shiftInfo.visitTotalTotal).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(shiftInfo.visitTotalClinic).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.visitTotalCosts).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.visits"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.takhasos.length">
                  <hr />
                  <h5>
                    <strong>ویزیت تخصصی</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.takhasTotalCountVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.takhasTotalTotalVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalClinicVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalCostsVisit
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.takhasos"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div
                  class="break-avoid"
                  v-if="shiftInfo.takhasosClinical.length"
                >
                  <hr />
                  <h5>
                    <strong>خدمات تخصصی</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.takhasTotalCountClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.takhasTotalTotalClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalClinicClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.takhasTotalCostsClinical
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.takhasosClinical"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.homeCare.length">
                  <hr />
                  <h5>
                    <strong>خدمات در منزل</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.homeCareTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.homeCareTotalCost).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="otherItemsField"
                    :items="shiftInfo.homeCare"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.balinis.length">
                  <hr />
                  <h5>
                    <strong>خدمات پرستاری</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.baliniTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(shiftInfo.baliniTotalTotal).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(shiftInfo.baliniTotalClinic).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.baliniTotalCosts).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.balinis"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.outBalinis.length">
                  <hr />
                  <h5>
                    <strong>خدمات پرستاری (خارج از درمانگاه)</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.baliniOutTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.outBalinis"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.surgery.length">
                  <hr />
                  <h5><strong>جراحی سرپایی </strong></h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.surgeryTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(shiftInfo.surgeryTotalTotal).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.surgeryTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.surgeryTotalCosts).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.surgery"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.surgeryOut.length">
                  <hr />
                  <h5>
                    <strong>جراحی سرپایی (خارج از درمانگاه)</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.surgeryOutTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.surgeryOut"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.dentistry.length">
                  <hr />
                  <h5>
                    <strong>دندانپزشکی</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.dentistryTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.dentistryTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.dentistryTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.dentistryTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="shiftInfo.dentistry"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.allLabs.length">
                  <hr />
                  <h5>
                    <strong>آزمایشگاه</strong>
                  </h5>

                  <v-row style="margin: 20px 10px">
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.laboratory[0].count
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.laboratory[0].totalCost
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.laboratory[0].totalCost
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="labFields"
                    :items="shiftInfo.laboratory"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.commodity.length">
                  <hr />
                  <h5 class="my-4"><strong>اقلام مصرفی</strong></h5>

                  <v-row style="margin: 20px 10px">
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.commodityTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.commodityTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(
                              shiftInfo.commodityTotalCosts
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="commodityField"
                    :items="shiftInfo.commodity"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="break-avoid" v-if="shiftInfo.others.length">
                  <hr />
                  <h5 class="my-4"><strong>خدمات دیگر</strong></h5>

                  <v-row style="margin: 20px 10px">
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(shiftInfo.otherTotalCount).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.otherTotalCost).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="otherItemsField"
                    :items="shiftInfo.others"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>

                <!--<div class="break-avoid" v-if="shiftInfo.medicine.length">
                  <hr />
                  <h5 class="my-4"><strong>دارو</strong></h5>

                  <v-row style="margin: 20px 10px">
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.medicineTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                     <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.medicineTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                      <div class="common-text common-div">
                        <strong>
                          <v-icon color="primary">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.medicineTotalCost).toLocaleString()
                          }}
                        </strong>
                      </div>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="commodityField"
                    :items="shiftInfo.medicine"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>-->
                <div class="break-avoid" v-if="shiftInfo.canceled.length">
                  <hr />
                  <h5 style="color: red">
                    <strong>خدمات لغو شده</strong>
                  </h5>

                  <v-row style="margin: 20px 10px; color: red">
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <h6 class="common-text common-div-red">
                        <strong>
                          <v-icon color="red">mdi-counter</v-icon>
                          تعداد :
                          {{
                            Number(
                              shiftInfo.canceledTotalCount
                            ).toLocaleString()
                          }}
                        </strong>
                      </h6>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <h6 class="common-text common-div-red">
                        <strong>
                          <v-icon color="red">mdi-hospital-building</v-icon>
                          کارکرد درمانگاه :
                          {{
                            Number(
                              shiftInfo.canceledTotalTotal
                            ).toLocaleString()
                          }}
                        </strong>
                      </h6>
                    </v-col>
                    <v-col
                      v-if="role != 'reception'"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <h6 class="common-text common-div-red">
                        <strong>
                          <v-icon color="red">mdi-cash-multiple</v-icon>
                          هزینه اقلام حفاظتی :
                          {{
                            Number(
                              shiftInfo.canceledTotalClinic
                            ).toLocaleString()
                          }}
                        </strong>
                      </h6>
                    </v-col>
                    <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      :md="role != 'reception' ? '3' : '4'"
                      :lg="role != 'reception' ? '3' : '4'"
                      :xl="role != 'reception' ? '3' : '4'"
                    >
                      <h6 class="common-text common-div-red">
                        <strong>
                          <v-icon color="red">mdi-cash-plus</v-icon>
                          دریافتی :
                          {{
                            Number(shiftInfo.canceledTotalCost).toLocaleString()
                          }}
                        </strong>
                      </h6>
                    </v-col>
                  </v-row>
                  <b-table
                    responsive
                    show-empty
                    :fields="canceledFields"
                    :items="shiftInfo.canceled"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <v-row class="my-4 pa-2" v-show="printing">
                  <v-col cols="6">
                    <div
                      class="px-3 py-4 rounded-xl"
                      style="height: 200px; border: 2px solid #00a7b7"
                    >
                      <span class="font-weight-bold h4">
                        محل امضای پذیرش:
                      </span>
                    </div>
                  </v-col>
                  <!--<v-col cols="6">
                    <div
                      v-if="
                        shiftInfo.doctorName.length == 1 && role == 'reception'
                      "
                      class="px-3 py-4 rounded-xl"
                      style="height: 200px; border: 2px solid #00a7b7"
                    >
                      <span class="font-weight-bold h4"> محل امضای پزشک: </span>
                    </div>
                  </v-col>-->
                </v-row>
              </v-card>
            </v-card>
          </v-tab-item>
          <!-- گزارش پایان شیفت -->
          <v-tab-item v-if="role == 'admin'">
            <v-card class="pa-5">
              <br />
              <h4>
                <v-row>
                  <v-col
                    >گزارش شیفت {{ report.shift }} -
                    {{
                      weekDict[moment(report.date, "jYYYY/jMM/jDD").weekday()]
                    }}
                    - تاریخ {{ report.date }}</v-col
                  >
                  <v-col
                    >از ساعت {{ report.shiftStart }} تا
                    {{ report.shiftEnd }}</v-col
                  >
                </v-row>
              </h4>
              <br />
              <hr />
              <h4 class="primary--text text-center mb-3">
                کادر درمان و پرسنل حاضر در شیفت
              </h4>
              <v-row>
                <v-col v-if="report.lastShiftDoc">
                  <p v-if="report.lastShiftDoc.length == 1">
                    پزشک: دکتر {{ report.lastShiftDoc[0] }}
                  </p>
                  <p v-else>
                    پزشکان:
                    <span
                      v-for="doc in report.lastShiftDoc"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </p>
                </v-col>
                <v-col v-if="report.lastShiftNurse">
                  <p v-if="report.lastShiftNurse.length == 1">
                    پرستار: {{ report.lastShiftNurse[0] }}
                  </p>
                  <p v-else>
                    پرستاران:
                    <span
                      v-for="nurse in report.lastShiftNurse"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="report.lastShiftDentist">
                  <p v-if="report.lastShiftDentist.length == 1">
                    دندانپزشک: دکتر {{ report.lastShiftDentist[0] }}
                  </p>
                  <p v-else>
                    دندانپزشکان:
                    <span
                      v-for="doc in report.lastShiftDentist"
                      :key="doc.index"
                      style="line-height: 30px"
                    >
                      - دکتر {{ doc }}</span
                    >
                  </p>
                </v-col>
                <v-col v-if="report.lastShiftNurseDentist">
                  <p v-if="report.lastShiftNurseDentist.length == 1">
                    دستیار دندانپزشک:
                    {{ report.lastShiftNurseDentist[0] }}
                  </p>
                  <p v-else>
                    دستیاران دندانپزشک:
                    <span
                      v-for="nurse in report.lastShiftNurseDentist"
                      :key="nurse.index"
                      style="line-height: 30px"
                    >
                      - {{ nurse }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="common-text">
                    کارمند پذیرش: {{ report.reception }}
                  </span>
                </v-col>
                <v-col v-if="report.lastShiftServant">
                  <p v-if="report.lastShiftServant.length == 1">
                    پرسنل خدمات: {{ report.lastShiftServant[0] }}
                  </p>
                  <p v-else>
                    پرسنل خدمات:
                    <span
                      v-for="servant in report.lastShiftServant"
                      :key="servant.index"
                      style="line-height: 30px"
                    >
                      - {{ servant }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <br />
              <hr />
              <h4 class="primary--text text-center mb-3">خلاصه کارکرد مالی</h4>
              <v-row>
                <v-col class="common-text">
                  دریافتی کارتخوان:
                  {{
                    shiftInfo.reportCard
                      ? Number(shiftInfo.reportCard).toLocaleString()
                      : 0
                  }}
                  ﷼
                </v-col>
                <v-col class="common-text">
                  دریافتی نقدی:
                  {{
                    shiftInfo.reportCash
                      ? Number(shiftInfo.reportCash).toLocaleString()
                      : 0
                  }}
                  ﷼
                </v-col>
              </v-row>
              <v-row>
                <v-col class="common-text">
                  مبلغ کسری صندوق:
                  {{ report.lack ? Number(report.lack).toLocaleString() : 0 }}
                  ﷼
                </v-col>
                <v-col class="common-text">
                  مبلغ اضافه صندوق:
                  {{ report.extra ? Number(report.extra).toLocaleString() : 0 }}
                  ﷼
                </v-col>
              </v-row>
              <v-row>
                <v-col class="common-text">
                  طلب از بیمار:
                  {{ report.talab ? Number(report.talab).toLocaleString() : 0 }}
                  ﷼
                </v-col>
                <v-col class="common-text">
                  بدهی به بیمار:
                  {{ report.debt ? Number(report.debt).toLocaleString() : 0 }}
                  ﷼
                </v-col>
              </v-row>
              <v-row>
                <v-col class="common-text">
                  توضیحات صندوق:
                  {{ report.cashDescription ? report.cashDescription : "-" }}
                </v-col>
              </v-row>
              <hr />
              <div>
                <h4 class="text-center primary--text">نسخ الکترونیک ثبت شده</h4>
                <br />
                <v-row>
                  <v-col md="4" cols="12" class="common-text">
                    بیمه تامین اجتماعی:
                    {{ report.ePrepSocial ? report.ePrepSocial : "-" }}
                  </v-col>
                  <v-col md="4" cols="12" class="common-text">
                    بیمه خدمات سلامت:
                    {{ report.ePrepHealth ? report.ePrepHealth : "-" }}
                  </v-col>
                  <v-col md="4" cols="12" class="common-text">
                    بیمه نیروهای مسلح :
                    {{ report.ePrepMilitary ? report.ePrepMilitary : "-" }}
                  </v-col>
                </v-row>
                <hr />
              </div>

              <div v-if="report.isReferral">
                <h4 class="text-center primary--text">ارجاعات تخصصی</h4>
                <br />
                <p style="line-height: 22px">
                  آیا در فرآیند اجرای شیفت، ارجاعی به متخصصین انجام گرفته است؟
                  <b>{{ report.isReferral == "yes" ? "بله" : "خیر" }}</b>
                </p>
                <div v-if="report.isReferral == 'yes'">
                  <v-row v-if="Number(referral.lab.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد آزمایشگاه:
                      {{ referral.lab ? referral.lab.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران آزمایشگاه:
                      {{
                        referral.lab
                          ? referral.lab.names
                            ? referral.lab.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.physio.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد فیزیوتراپی:
                      {{ referral.physio ? referral.physio.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران فیزیوتراپی:
                      {{
                        referral.physio
                          ? referral.physio.names
                            ? referral.physio.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.radio.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد رادیولوژی:
                      {{ referral.radio ? referral.radio.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران رادیولوژی:
                      {{
                        referral.radio
                          ? referral.radio.names
                            ? referral.radio.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.dentistery.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد دندانپزشکی:
                      {{ referral.dentistery ? referral.dentistery.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران دندانپزشکی:
                      {{
                        referral.dentistery
                          ? referral.dentistery.names
                            ? referral.dentistery.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.women.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد متخصص زنان:
                      {{ referral.women ? referral.women.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران متخصص زنان:
                      {{
                        referral.women
                          ? referral.women.names
                            ? referral.women.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.babies.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد متخصص اطفال:
                      {{ referral.babies ? referral.babies.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران متخصص اطفال:
                      {{
                        referral.babies
                          ? referral.babies.names
                            ? referral.babies.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.internal.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد داخلی:
                      {{ referral.internal ? referral.internal.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران داخلی:
                      {{
                        referral.internal
                          ? referral.internal.names
                            ? referral.internal.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.heart.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد قلب و عروق:
                      {{ referral.heart ? referral.heart.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران قلب و عروق:
                      {{
                        referral.heart
                          ? referral.heart.names
                            ? referral.heart.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.ortho.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد ارتوپدی:
                      {{ referral.ortho ? referral.ortho.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران ارتوپدی:
                      {{
                        referral.ortho
                          ? referral.ortho.names
                            ? referral.ortho.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.ent.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد ENT:
                      {{ referral.ent ? referral.ent.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران ENT:
                      {{
                        referral.ent
                          ? referral.ent.names
                            ? referral.ent.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.surgery.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد جراحی:
                      {{ referral.surgery ? referral.surgery.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران جراحی:
                      {{
                        referral.surgery
                          ? referral.surgery.names
                            ? referral.surgery.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.orology.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد اورولوژی:
                      {{ referral.orology ? referral.orology.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران اورولوژی:
                      {{
                        referral.orology
                          ? referral.orology.names
                            ? referral.orology.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.infectious.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد عفونی:
                      {{ referral.infectious ? referral.infectious.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران عفونی:
                      {{
                        referral.infectious
                          ? referral.infectious.names
                            ? referral.infectious.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="Number(referral.skin.no) > 0">
                    <v-col md="2" cols="12" class="common-text">
                      تعداد پوست و مو:
                      {{ referral.skin ? referral.skin.no : "0" }}
                    </v-col>
                    <v-col md="10" cols="12" class="common-text">
                      اسامی بیماران پوست و مو:
                      {{
                        referral.skin
                          ? referral.skin.names
                            ? referral.skin.names
                            : "-"
                          : "-"
                      }}
                    </v-col>
                  </v-row>
                </div>
                <hr />
              </div>

              <h4 class="primary--text text-center mb-3">خلاصه اتفاقات شیفت</h4>
              <p style="line-height: 22px">
                1. آیا کمبودی در وسایل و امکانات و تجهیزات در شیفت وجود داشت؟
                <b>{{
                  report.isFacilitiesShortage == "yes" ? "بله" : "خیر"
                }}</b>
              </p>
              <p
                v-if="report.isFacilitiesShortage == 'yes'"
                style="line-height: 22px"
              >
                توضیحات و شرح کمبود: {{ report.facilitiesShortage }}
              </p>
              <p style="line-height: 22px">
                2. آیا در طول مدت شیفت بیمار یا مراجعه کننده ای اعتراض /ناراحتی/
                شکایت یا درگیری در خصوص نحوه ارایه خدمات درمانگاه اعم از
                خدمات/پذیرش/ پرستاری/ ازمایشگاه/ دندانپزشکی/ داروخانه و یا پزشک
                درمانگاه داشت؟
                <b>{{ report.isDissatisfaction == "yes" ? "بله" : "خیر" }}</b>
              </p>
              <p
                v-if="report.isDissatisfaction == 'yes'"
                style="line-height: 22px"
              >
                توضیحات و شرح نارضایتی: {{ report.dissatisfaction }}
              </p>
              <div>
                <h6 style="line-height: 22px">سایر موارد:</h6>
                <p
                  v-if="report.others"
                  class="c-grey fs-medium text-justify"
                  v-html="report.others"
                ></p>
                <span v-else>-</span>
              </div>
              <div>
                <h6 style="line-height: 22px">
                  پیشنهاد جهت ارتقاء فرآیند کاری درمانگاه:
                </h6>
                <p
                  v-if="report.suggestions"
                  class="c-grey fs-medium text-justify"
                  v-html="report.suggestions"
                ></p>
                <span v-else>-</span>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";

export default {
  data() {
    return {
      deviceType: "",
      tab: null,
      innerTab: null,
      pageLoaded: false,
      shiftInfo: {},
      report: {},
      referral: {},
      excelDocItems: [],
      moment: moment,
      weekDict: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه شنبه",
        "چهارشنبه",
        "پنج شنبه",
        "جمعه",
      ],
      Busy: false,
      inOutFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "role", label: "نقش" },
        { key: "start", label: "آغاز" },
        { key: "end", label: "پایان" },
      ],
      otherItemsField: [
        { key: "index", label: "#" },
        { key: "name", label: "خدمت" },
        { key: "count", label: "تعداد" },
        { key: "totalCost", label: "مبلغ دریافتی" },
      ],
      commodityField: [
        { key: "index", label: "#" },
        { key: "name", label: "نام" },
        { key: "count", label: "تعداد" },
        { key: "totalCost", label: "قیمت" },
      ],
      canceledFields: [
        { key: "index", label: "#" },
        { key: "name", label: "خدمت" },
        { key: "count", label: "تعداد" },
        { key: "total", label: "کارکرد درمانگاه" },
        // { key: "totalExpens", label: "هزینه مواد مصرفی" },
        { key: "totalCost", label: "مبلغ دریافتی" },
      ],
      labRequestFields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "date", label: "تاریخ" },
        { key: "cost", label: "هزینه آزمایش" },
      ],
      labFinancialFields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "date", label: "تاریخ" },
        { key: "type", label: "نوع تراکنش" },
        { key: "transferType", label: "شیوه تراکنش" },
        { key: "amount", label: "هزینه آزمایش" },
        { key: "description", label: "توضیحات" },
      ],
      dentistryFinancialFields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "date", label: "تاریخ" },
        { key: "type", label: "نوع تراکنش" },
        { key: "transferType", label: "شیوه تراکنش" },
        { key: "amount", label: "هزینه ویزیت" },
        //{ key: "description", label: "توضیحات" },
      ],
      charityFields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "date", label: "تاریخ" },
        { key: "amount", label: "مقدار" },
        { key: "description", label: "توضیحات" },
      ],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      printing: false,
      infoDialog: false,
    };
  },

  methods: {
    getShiftInfo() {
      //NOTE getting all shift data from server when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/shift",
          {
            shiftId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.shiftInfo = res.data;

            //adding general totals to the shiftInfo

            //this.shiftInfo.medicineTotalCost = 3000;
            //this.shiftInfo.medicineTotalTotal = 3000;
            //this.shiftInfo.medicineTotalCount = 30;
            //this.shiftInfo.medicine = [
            //  {
            //    name: "تست",
            //    count: 20,
            //    price: 1000,
            //  },
            //  {
            //    name: "۲ تست",
            //    count: 10,
            //    price: 2000,
            //  },
            //];
            this.shiftInfo.generalTotal = [];
            let generalTotalTemp = {};
            generalTotalTemp.generalTotalCount =
              this.shiftInfo.visitTotalCount +
              this.shiftInfo.baliniTotalCount +
              this.shiftInfo.baliniOutTotalCount +
              this.shiftInfo.surgeryTotalCount +
              this.shiftInfo.surgeryOutTotalCount;
            generalTotalTemp.generalTotalTotal =
              this.shiftInfo.visitTotalTotal +
              this.shiftInfo.baliniTotalTotal +
              this.shiftInfo.baliniOutTotalTotal +
              this.shiftInfo.surgeryTotalTotal +
              this.shiftInfo.surgeryOutTotalTotal;
            generalTotalTemp.generalTotalClinic =
              this.shiftInfo.visitTotalClinic +
              this.shiftInfo.baliniTotalClinic +
              this.shiftInfo.baliniOutTotalClinic +
              this.shiftInfo.surgeryTotalClinic +
              this.shiftInfo.surgeryOutTotalClinic;
            generalTotalTemp.generalTotalExpenses =
              this.shiftInfo.visitTotalExpenses +
              this.shiftInfo.baliniTotalExpenses +
              this.shiftInfo.baliniOutTotalExpenses +
              this.shiftInfo.surgeryTotalExpenses +
              this.shiftInfo.surgeryOutTotalExpenses;
            generalTotalTemp.generalTotalCosts =
              this.shiftInfo.visitTotalCosts +
              this.shiftInfo.baliniTotalCosts +
              this.shiftInfo.baliniOutTotalCosts +
              this.shiftInfo.surgeryTotalCosts +
              this.shiftInfo.surgeryOutTotalCosts;
            this.shiftInfo.generalTotal[0] = generalTotalTemp;
            this.pageLoaded = true;
            this.excelDocItems = this.shiftInfo.visits.concat(
              this.shiftInfo.takhasos,
              this.shiftInfo.takhasosClinical,
              this.shiftInfo.homeCare,
              this.shiftInfo.balinis,
              this.shiftInfo.outBalinis,
              this.shiftInfo.surgery,
              this.shiftInfo.surgeryOut,
              this.shiftInfo.others,
              this.shiftInfo.dentistry,
              this.shiftInfo.laboratory
            );
            try {
              this.report = JSON.parse(this.shiftInfo.report);
              this.referral = this.report.referral ? this.report.referral : {};
            } catch (error) {
              this.report = {};
            }
            setTimeout(() => {
              let parent = document.getElementById("tabs");
              let children = parent.querySelectorAll(".v-tab");
              if (this.role == "reception") {
                this.tab = children.length - 1;
              } else if (this.role == "admin") {
                this.tab = children.length - 2;
              }
            }, 200);
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    printShiftReport() {
      this.printing = true;
      // Use nextTick to trigger the print on next update
      this.$nextTick(() => {
        this.$htmlToPaper("shiftReport", null, () => {
          this.printing = false; // hide the <p> tag when printing is done
        });
      });
    },
  },
  computed: {
    excelDocFields() {
      let fields =
        this.role == "reception"
          ? [
              { key: "name", label: "خدمت" },
              { key: "count", label: "تعداد" },
              { key: "total", label: "کارکرد درمانگاه" },
              // { key: "totalExpens", label: "هزینه مواد مصرفی" },
              { key: "totalCost", label: "مبلغ دریافتی" },
            ]
          : [
              { key: "name", label: "خدمت" },
              { key: "count", label: "تعداد" },
              { key: "total", label: "کارکرد درمانگاه" },
              { key: "totalClinicShare", label: "هزینه اقلام حفاظتی" },
              // { key: "totalExpens", label: "هزینه مواد مصرفی" },
              { key: "totalCost", label: "مبلغ دریافتی" },
            ];
      return fields;
    },
    computedDisabled() {
      if (
        !this.shiftInfo.visits.length &&
        !this.shiftInfo.takhasos.length &&
        !this.shiftInfo.takhasosClinical.length &&
        !this.shiftInfo.homeCare.length &&
        !this.shiftInfo.balinis.length &&
        !this.shiftInfo.outBalinis.length &&
        !this.shiftInfo.surgery.length &&
        !this.shiftInfo.surgeryOut.length &&
        !this.shiftInfo.others.length &&
        !this.shiftInfo.dentistry.length &&
        !this.shiftInfo.laboratory
      ) {
        return true;
      } else {
        return false;
      }
    },
    Fields() {
      let fields = [];
      if (this.role == "reception") {
        fields = [
          { key: "index", label: "#" },
          { key: "name", label: "خدمت" },
          { key: "count", label: "تعداد" },
          { key: "total", label: "کارکرد درمانگاه" },
          // { key: "totalExpens", label: "هزینه مواد مصرفی" },
          { key: "totalCost", label: "مبلغ دریافتی" },
        ];
      } else {
        fields = [
          { key: "index", label: "#" },
          { key: "name", label: "خدمت" },
          { key: "count", label: "تعداد" },
          { key: "total", label: "کارکرد درمانگاه" },
          { key: "totalClinicShare", label: "هزینه اقلام حفاظتی" },
          // { key: "totalExpens", label: "هزینه مواد مصرفی" },
          { key: "totalCost", label: "مبلغ دریافتی" },
        ];
      }

      return fields;
    },
    labFields() {
      let fields =
        this.role == "reception"
          ? [
              { key: "index", label: "#" },
              { key: "name", label: "خدمت" },
              { key: "count", label: "تعداد" },
              { key: "total", label: "کارکرد درمانگاه" },
              { key: "totalCost", label: "مبلغ دریافتی" },
            ]
          : [
              { key: "index", label: "#" },
              { key: "name", label: "خدمت" },
              { key: "count", label: "تعداد" },
              { key: "total", label: "کارکرد درمانگاه" },
              { key: "totalCost", label: "مبلغ دریافتی" },
            ];
      return fields;
    },
    takhasTotalField() {
      let fields =
        this.role == "reception"
          ? [
              { key: "takhasTotalCountTotal", label: "تعداد" },
              { key: "takhasTotalTotalTotal", label: "کارکرد تخصصی" },
              // { key: "takhasTotalExpensesTotal", label: "هزینه مواد مصرفی" },
              { key: "takhasTotalCostsTotal", label: "مبلغ دریافتی" },
            ]
          : [
              { key: "takhasTotalCountTotal", label: "تعداد" },
              { key: "takhasTotalTotalTotal", label: "کارکرد تخصصی" },
              { key: "takhasTotalClinicTotal", label: "هزینه اقلام حفاظتی" },
              // { key: "takhasTotalExpensesTotal", label: "هزینه مواد مصرفی" },
              { key: "takhasTotalCostsTotal", label: "مبلغ دریافتی" },
            ];
      return fields;
    },
    generalTotalField() {
      let fields =
        this.role == "reception"
          ? [
              { key: "generalTotalCount", label: "تعداد" },
              { key: "generalTotalTotal", label: "کارکرد تخصصی" },
              // { key: "generalTotalExpenses", label: "هزینه مواد مصرفی" },
              { key: "generalTotalCosts", label: "مبلغ دریافتی" },
            ]
          : [
              { key: "generalTotalCount", label: "تعداد" },
              { key: "generalTotalTotal", label: "کارکرد تخصصی" },
              { key: "generalTotalClinic", label: "هزینه اقلام حفاظتی" },
              // { key: "generalTotalExpenses", label: "هزینه مواد مصرفی" },
              { key: "generalTotalCosts", label: "مبلغ دریافتی" },
            ];
      return fields;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");

    this.getShiftInfo();
  },
};
</script>
<style lang="scss">
.resultBox {
  padding: 10px;
}

@media print {
  .dontPrint {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-tabs-slider {
  display: none;
}
</style>
